import React, { useEffect } from "react";
import axios from "axios";
import AWS from "aws-sdk";
import { jwtDecode } from "jwt-decode";
import { STSClient, AssumeRoleCommand } from "@aws-sdk/client-sts";
import { QBusinessClient, ChatSyncCommand } from "@aws-sdk/client-qbusiness";
import {
  applicationID,
  pluginData,
  roleDetails,
} from "static-contents/chatbot-data";
import { postApiCall } from "helper/postApiCall";

const region = "us-east-1";
// let isRefreshTokenType = false;
let refreshToken = null;

export const qConfig = (
  message,
  setSessionAlert,
  idToken,
  setRefreshTokenType = () => {},
  isRefreshTokenType,
  applicationType = null,
  conversationId,
  parentMessageId,
  setConversationId,
  setParentMessageId,
  isConformityAlert,
  isConformityResolution
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await postApiCall(
        "https://969cq6ji04.execute-api.us-east-1.amazonaws.com/Test/getToken",
        idToken,
        {
          token: isRefreshTokenType ? refreshToken : idToken,
          isRefreshToken: isRefreshTokenType,
        }
      );

      if (response) {
        refreshToken = response.data.body.refreshToken;
        setRefreshTokenType(true);
        const decoded = jwtDecode(response.data.body.idToken);
        const sts = new STSClient({
          credentials: {
            accessKeyId: response.data.body.configId,
            secretAccessKey: response.data.body.configKey,
            sessionToken: null,
          },
          region: region,
        });

        const assumeRoleCommand = new AssumeRoleCommand({
          RoleArn: roleDetails.roleArn,
          RoleSessionName: roleDetails.roleSessionName,
          region: region,
          ProvidedContexts: [
            {
              ProviderArn: "arn:aws:iam::aws:contextProvider/IdentityCenter",
              ContextAssertion: decoded["sts:identity_context"],
            },
          ],
        });
        const assumeRoleResponse = await sts.send(assumeRoleCommand);

        const identityBearerSessionCredentials = {
          accessKeyId: assumeRoleResponse.Credentials.AccessKeyId,
          secretAccessKey: assumeRoleResponse.Credentials.SecretAccessKey,
          sessionToken: assumeRoleResponse.Credentials.SessionToken,
        };

        //Amazon Q quicksight
        const client = new QBusinessClient({
          credentials: identityBearerSessionCredentials,
          region: region,
        });
        const pluginIdentity =
          applicationType === "FAQ"
            ? pluginData?.faq
            : applicationType === "Finance"
            ? pluginData?.finance
            : pluginData?.conformity;

        const applicationIdentity =
          applicationType === "FAQ"
            ? applicationID?.faq
            : applicationType === "Finance"
            ? applicationID?.finance
            : applicationID?.conformity;

        const input = {
          applicationId: applicationIdentity,
          userMessage: message,
        };
        if (
          applicationType !== "FAQ" &&
          !isConformityResolution &&
          applicationType !== "Finance"
        ) {
          input.chatMode = "PLUGIN_MODE";
          input.chatModeConfiguration = {
            // ChatModeConfiguration Union: only one key present
            pluginConfiguration: {
              // PluginConfiguration
              pluginId: pluginIdentity, // required
            },
          };
        }
        // console.log("input", input);
        // console.log("applicationType", applicationType);

        if (conversationId) {
          input.conversationId = conversationId;
          input.parentMessageId = parentMessageId;
        }

        // chat sync api ---------------//
        const command = new ChatSyncCommand(input);
        let messageFromQ;
        client
          .send(command)
          .then(async (response) => {
            // console.log("Q ChatBot Response", response);
            if (Object.keys(response).length === 0) {
              resolve(
                "Sorry your request could not be processed. Please retry."
              );
            } else {
              setConversationId(response?.conversationId);
              setParentMessageId(response?.systemMessageId);
              if (response?.actionReview) {
                if (
                  applicationType !== "FAQ" &&
                  applicationType !== "Finance"
                ) {
                  const {
                    actionReview: {
                      payload: { query },
                    },
                  } = response;
                  messageFromQ = query;
                } else {
                  const refactorQ = response?.systemMessage.replace(
                    "I'm Q, an AI assistant created by Amazon Web Services.",
                    "I'm Neo, an AI assistant created by Team GCS."
                  );
                  messageFromQ = refactorQ;
                }
              } else {
                const refactorQ = response?.systemMessage.replace(
                  "I'm Q, an AI assistant created by Amazon Web Services.",
                  "I'm Neo, an AI assistant created by Team GCS."
                );
                messageFromQ = refactorQ;
              }
            }
            resolve(messageFromQ);
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error) {
      //   reject("session expired");
      setSessionAlert(true);
    }
  });
};
