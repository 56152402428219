import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { useTranslation } from "react-i18next";

// Import Icon
import cloudIcon from "images/cloud-icon.svg";
import userAvatar from "images/avatar-default-svgrepo-com.svg";
import gcpLogo from "icons/google-cloud-preference.svg";

// Import Constant
import { USER_INFO } from "redux/user/userConstant";

const NotificationCloudPreference = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const { authState, oktaAuth } = useOktaAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch]);
  return (
    <>
      {userInfo !== null && authState && authState.isAuthenticated && (
        <div className="header-section-new flex-column">
          <p className="cloud-preference-p">{t("Notification Preference")}</p>
          <div className="d-flex flex-column flex-md-row gap-4">
            <Link
              to={{
                pathname: "/notification",
                state: "cloudPic",
              }}
              className="text-decoration-none"
            >
              <div className="logoContainer flex-column">
                <img
                  src={cloudIcon}
                  alt="AWS"
                  className="cloud-preference-img"
                />
                {/* <p className="notification-cloud-img">C</p> */}
                <p className="color-black">Cloud Pic's</p>
              </div>
            </Link>
            <Link
              to={{
                pathname: "/notification",
                state: "nonCloudPic",
              }}
              className="text-decoration-none"
            >
              <div className="logoContainer flex-column">
                <img
                  src={userAvatar}
                  alt="AWS"
                  className="cloud-preference-img"
                />
                <p className="color-black">Non Cloud Pic's</p>
              </div>
            </Link>

            {/*             
              <div className="logoContainer flex-column">
                <img
                  src={gcpLogo}
                  alt="GCP"
                  className="cloud-preference-img"
                  style={{ marginLeft: "0px" }}
                />
                <p className="color-black">Google Project</p>
              </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationCloudPreference;
