import React from "react";
import { Link } from "react-router-dom";

const NewRequestSubmit = ({ refUid, errorMessageValue }) => {
  return (
    <>
      {errorMessageValue != null && (
        <p>
          <i className="fa fa fa-remove cidr-error-mark"></i>
        </p>
      )}
      {refUid != null && (
        <p>
          <i className="fa fa-check-circle cidr-check-mark"></i>
        </p>
      )}
      <div className="d-flex justify-content-center flex-column">
        {refUid != null && (
          <>
            <p>Your request was submitted successfully</p>
            <p>Check your status using this ref#</p>
            <p>{refUid}</p>
          </>
        )}
        {errorMessageValue != null && (
          <>
            <p className="text-danger">{errorMessageValue}</p>
          </>
        )}
        <p>
          <button type="button" className="teal-filled-btn">
            <Link to="/ai-request-view">Ok</Link>
          </button>
        </p>
      </div>
    </>
  );
};

export default NewRequestSubmit;
