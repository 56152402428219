import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";

import Loader from "components/Common/Loader";
import AlertMessage from "components/Common/AlertMessage";

import { postApiCall } from "helper/postApiCall";
import {
  CS_Hub_AIUsecases_Infosec,
  CS_Hub_AIUsecases_Risk,
  CS_Hub_AWS_Account_Request_Admin,
} from "roles/roles";

const Approve = ({ yesParent, closeModalParent, status, uid }) => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [approveReason, setApproveReason] = useState(null);
  const [showMandatory, setShowMandatory] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [approved, setApproved] = useState(false);

  async function approveRequest() {
    if (approveReason) {
      setLoading(true);
      const idToken = userInfo.idToken;
      const requestBody = {
        uid: uid,
        actionType: 1,
        actiontakenby: "muthukumar.chithiraisamy@sony.com",
      };
      if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
        requestBody.gcsactioncomments = approveReason;
        requestBody.type_of_user = 1;
      }
      if (userInfo.groups.includes(CS_Hub_AIUsecases_Infosec)) {
        requestBody.infosecactioncomments = approveReason;
        requestBody.type_of_user = 2;
      }
      if (userInfo.groups.includes(CS_Hub_AIUsecases_Risk)) {
        requestBody.riskcactioncomments = approveReason;
        requestBody.type_of_user = 3;
      }

      const getApiUrl = ``;
      // const getApiUrl = `${process.env.REACT_APP_AWS_WORKSPACES_REQUEST}`;

      const setApiUrl = `${getApiUrl}`;
      await postApiCall(setApiUrl, idToken, requestBody)
        .then((response) => {
          if (response.data.ResponseMetadata.HTTPStatusCode === 200) {
            setApproved(true);
          } else {
            setError("Failed to update");
          }
        })
        .catch((err) => {
          setError("Failed to update");
        });
    } else {
      setShowMandatory(true);
    }
  }
  return (
    <div className="d-flex justify-content-center flex-column">
      {approved === false && (
        <>
          <p>Are you sure you want to approve this request</p>
          <Row>
            <Col md={2} />
            <Col md={8}>
              <Form.Group className="mb-3" controlId="bundleDescription">
                <Form.Label>{"Reason for approve"}</Form.Label>
                <Form.Control
                  class={`form-control ${showMandatory ? "is-invalid" : ""}`}
                  as="textarea"
                  style={{ whiteSpace: "normal" }}
                  rows={3}
                  value={approveReason}
                  onChange={(e) => {
                    setApproveReason(e.target.value);
                    setShowMandatory(true);
                  }}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={2} />
          </Row>
          {error !== null && (
            <div className="d-flex justify-content-center flex-column">
              <AlertMessage
                cssClassName="alert-message-danger"
                alertMessage={error}
              />
            </div>
          )}
          {loading && <Loader />}
          {loading === false && error === null && (
            <ul className="btn-ul">
              <li>
                <button
                  type="button"
                  className="teal-bordered-btn"
                  onClick={() => {
                    closeModalParent(null);
                  }}
                >
                  No
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="teal-filled-btn"
                  onClick={() => {
                    approveRequest();
                  }}
                >
                  Yes
                </button>
              </li>
            </ul>
          )}
        </>
      )}
      {approved === true && (
        <>
          <p>Request Approved!</p>
          <p>
            <button
              type="button"
              className="teal-filled-btn"
              onClick={() => {
                yesParent(null);
              }}
            >
              Ok
            </button>
          </p>
        </>
      )}
    </div>
  );
};

export default Approve;
