// in MessageParser.jsx
import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import axios from "axios";
import { postApiCall } from "helper/postApiCall";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";
import { FIRST_INPUT_ENTER } from "redux/chatbot/chatBotConstant";
// Generate a unique session ID (example using UUID)
// const { v4: uuidv4 } = require("uuid");
// const sessionId = uuidv4();
const sts = new AWS.STS();

const roleArn = "arn:aws:iam::058264283013:role/lexTest";
const region = "us-east-1";

const MessageParser = ({ children, actions, state }) => {
  const dispatch = useDispatch();
  const isAccountIdPrompt = useSelector(
    (state) => state.chatBot.isAccountIdPrompt
  );
  const isFinancePrompt = useSelector((state) => state.chatBot.isFinancePrompt);
  const applicationType = useSelector((state) => state.chatBot.applicationType);
  const hasFirstEnter = useSelector((state) => state.chatBot.isFirstText);
  let idToken;
  // const { authState, oktaAuth } = useOktaAuth();

  const parse = async (message) => {
    // if (authState?.isAuthenticated) {
    //   idToken = oktaAuth.getIdToken();
    // }

    if (message && applicationType === "Security") {
      actions.handleAccountInfo(message);
    }
    if (message && applicationType === "Finance") {
      actions.handleFinance(message);
    }
    if (message && applicationType === "FAQ") {
      actions.handleFaqMessage(message);
    }
    if (message && !applicationType) {
      if (!hasFirstEnter) {
        dispatch({
          type: FIRST_INPUT_ENTER,
          payload: true,
        });
      }
      actions.handleValidation("error");
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
