export const qRequestValidate = (values) => {
  let errors = {};
  if (!values?.globalId) {
    errors.globalId = "Global ID is required";
  }

  let isValidEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    values?.email
  );
  if (!values?.email) {
    errors.email = "Email address is required";
  } else if (isValidEmail === false) {
    errors.email = "Email address is invalid";
  }

  if (!values?.cloudService) {
    errors.cloudService = "Cloud Service is required";
  }
  if (!values?.applicationName) {
    errors.applicationName = "Project Name / Application Name is required";
  }
  if (!values?.additionalComments) {
    errors.additionalComments = "Additional Comments is required";
  }

  // if (!values?.supportingDocumentName) {
  //   errors.supportingDocumentName = "Supporting Documents is required";
  // }

  return errors;
};
