export const DefaultAWSData = {
  aiRequestDetails: {
    globalId: "",
    email: "",
    applicationName: "",
    cloudService: "",
    additionalComments: "",
    requestStatus: "",
    uid: "",
    submittedBy: "",
  },
};

export const AWSReducer = (awsData, { type, payload }) => {
  switch (type) {
    case "UPDATE_Q_REQUEST":
      return {
        ...awsData,
        aiRequestDetails: {
          ...awsData.aiRequestDetails,
          ...payload,
        },
      };

    default:
      return awsData;
  }
};
