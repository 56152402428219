import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Loader from "../components/Loader";
import { qConfig } from "./qConfig";
import WarningMessage from "../components/WarningMessage";
import CustomModal from "components/Modal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
  ACCOUNT_ID_PROMPT,
  FINANCE_PROMPT,
  IS_LOADING,
} from "redux/chatbot/chatBotConstant";
import { useOktaAuth } from "@okta/okta-react";
import { postApiCall } from "helper/postApiCall";
import TableComponent from "../components/TableComponent";

const ActionProvider = ({
  createChatBotMessage,
  setState,
  state,
  children,
}) => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  const accountList = useSelector((state) => state.chatBot.accountList);
  const isAlertRuleId = useSelector((state) => state.chatBot.isAlertRuleId);
  const isAlertDesc = useSelector((state) => state.chatBot.isAlertDesc);
  const applicationType = useSelector((state) => state.chatBot.applicationType);
  const isSecurityAccountId = useSelector(
    (state) => state.chatBot.isAccountIdPrompt
  );
  const isConformityAlert = useSelector(
    (state) => state.chatBot.isConformityAlert
  );
  const isConformityResolution = useSelector(
    (state) => state.chatBot.isConformityResolution
  );

  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const idToken = userInfo?.idToken;
  const [sessionAlert, setSessionAlert] = useState(false);
  const [isRefreshTokenType, setRefreshTokenType] = useState(null);
  const [storedToken, setStoredToken] = useState(null);
  const [storedAccNo, setStoredAccNo] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [parentMessageId, setParentMessageId] = useState(null);

  //API DETAILS
  const apiUrl = process.env.REACT_APP_CHATBOT_SECURITY;
  const rephraseQuery = (val) => {
    let queryMessage;
    if (applicationType === "Security") {
      queryMessage = `${val} for user ${userInfo?.email}`;
    } else {
      queryMessage = val;
    }
    return queryMessage;
  };

  useEffect(() => {
    if (authState?.isAuthenticated && !storedToken) {
      const getIdToken = oktaAuth.getIdToken();
      setStoredToken(getIdToken);
    }
  }, [authState?.isAuthenticated, idToken]);

  const updateChatbotState = (message) => {
    setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };

  const handleConfirmity = () => {
    const message = createChatBotMessage(
      "Please provide your account id to proceed."
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
    dispatch({
      type: ACCOUNT_ID_PROMPT,
      payload: true,
    });
  };

  const handleHubDocuments = (skipHeader = null) => {
    const message = createChatBotMessage(
      skipHeader ? <h6></h6> : "Let's get started. You can ask me for:",
      {
        widget: "faqPrompt",
      }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const handleAccountInfo = (value, skipHeader = null) => {
    if (value) {
      handleHello(value, storedToken);
    } else {
      const message = createChatBotMessage(
        skipHeader ? <h6></h6> : "Let's get started. You can ask me for:",
        {
          widget: "SecurityFirstPrompt",
        }
      );
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message],
      }));
    }
  };

  const handleAccountAlerts = (trigger) => {
    if (trigger === "alertCount") {
      const message = createChatBotMessage(
        "Let's get started. You can ask me for:",
        {
          widget: "SecurityFirstAnswerPrompt",
        }
      );
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message],
      }));
    }
    if (trigger === "alertResolution") {
      const message = createChatBotMessage(
        "Let's get started. You can ask me for:",
        {
          widget: "SecuritySecondPrompt",
        }
      );
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message],
      }));
    }
    if (trigger === "alertRuleId") {
      const conformity =
        'How can i resolve alert "S3 cross account access alert"?';
      handleHello(conformity, storedToken);
    }
    if (trigger === "alertDesc") {
      const message = createChatBotMessage(
        "kindly share the alert description"
      );
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message],
      }));
    }
    if (trigger === "alertRule") {
      const conformity = `How many alerts for rule '0.0 open' in all my aws accounts?`;
      handleHello(conformity, storedToken);
    }
    if (trigger === "alertsTotal") {
      const conformity = `How many alerts in total for all my AWS accounts?`;
      handleHello(conformity, storedToken);
    }
  };

  const handleFinance = (value, skipHeader = null) => {
    if (value) {
      handleHello(value, storedToken);
    } else {
      const message = createChatBotMessage(
        skipHeader ? <h6></h6> : "Let's get started. You can ask me for:",
        {
          widget: "FinancePrompt",
        }
      );
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message],
      }));
    }
    dispatch({
      type: FINANCE_PROMPT,
      payload: true,
    });
    dispatch({
      type: ACCOUNT_ID_PROMPT,
      payload: true,
    });
  };

  const handleFinancePrompt = (value, storedToken) => {
    // const isValidAccount = accountList.find(
    //   (account) => account.value === value
    // );
    // if (isSecurityAccountId) {
    //   if (isValidAccount) {
    //     dispatch({
    //       type: ACCOUNT_ID_PROMPT,
    //       payload: false,
    //     });
    //     dispatch({
    //       type: FINANCE_PROMPT,
    //       payload: false,
    //     });
    //     setStoredToken(storedToken);
    //     setStoredAccNo(value);
    //     const message = createChatBotMessage(
    //       "Let's get started. You can ask me for:",
    //       {
    //         widget: "FinancePrompt",
    //       }
    //     );
    //     setState((prev) => ({
    //       ...prev,
    //       messages: [...prev.messages, message],
    //     }));
    //   } else {
    //     const message = createChatBotMessage(
    //       "you are not authorize to view information about this account. Please provide valid account id to proceed."
    //     );
    //     setState((prev) => ({
    //       ...prev,
    //       messages: [...prev.messages, message],
    //     }));
    //   }
    // }
    //  else {
    //   handleHello(value, storedToken);
    // }
  };

  const handleFinanceInfo = (trigger) => {
    if (trigger === "hostingCurrent") {
      const billingQstn = `what is the hosting charges for current month? `;
      handleHello(billingQstn, storedToken);
    }
    if (trigger === "hosting6") {
      const adjustmentQstn = `what is the hosting charges for may and june and july 24?, show it in a tabular form`;
      handleHello(adjustmentQstn, storedToken);
    }
  };

  const handleFaq = (trigger) => {
    if (trigger === "goldenAMI") {
      const oktaAccount = "What is Golden AMI?, add bullet points";
      handleHello(oktaAccount, storedToken);
    }
    if (trigger === "tagPolicy") {
      const awsAccount =
        "What is tagging Policy in GCS Organization??, add bullet points";
      handleHello(awsAccount, storedToken);
    }
    if (trigger === "getGitLab") {
      const faq = "How to get access to GitLab account?, add bullet points";
      handleHello(faq, storedToken);
    }
    if (trigger === "gitlabAccount") {
      const faq =
        "How to activate my blocked GitLab Account?, add bullet points";
      handleHello(faq, storedToken);
    }
    if (trigger === "awsAccess") {
      const faq = "How to request access to my AWS Account?, add bullet points";
      handleHello(faq, storedToken);
    }
    if (trigger === "networkMesh") {
      const faq =
        "Summarize the steps breifly to add account to mesh?, add bullet points";
      handleHello(faq, storedToken);
    }
  };

  const handleFaqMessage = (value, storedToken) => {
    const faqQuery = `${value}, add bullet points`;
    handleHello(faqQuery, storedToken);
  };

  const handleValidation = () => {
    const message = createChatBotMessage(
      "Please choose one of the options below to proceed."
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };
  const refreshPrompt = () => {
    if (applicationType === "Security") {
      handleAccountInfo(null, "skipHeader");
    }
    if (applicationType === "Finance") {
      handleFinance(null, "skipHeader");
    }
    if (applicationType === "FAQ") {
      handleHubDocuments("skipHeader");
    }
  };
  const handleHello = async (value, tokenId) => {
    let queryMessage = rephraseQuery(value);
    const loader = createChatBotMessage(<Loader />);
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, loader],
      isInputDisabled: true,
    }));

    const responseMessage = await qConfig(
      queryMessage,
      setSessionAlert,
      storedToken,
      setRefreshTokenType,
      isRefreshTokenType,
      applicationType,
      conversationId,
      parentMessageId,
      setConversationId,
      setParentMessageId,
      isConformityAlert,
      isConformityResolution
    );

    if (responseMessage && applicationType === "FAQ") {
      const getResponse = responseMessage?.replace("Add bullet points", "");
      const refactored = getResponse?.replace("add bullet points", "");
      // const enhancedResponse = refactored?.replace("\n", "\n\n");
      const botMessage = createChatBotMessage(refactored);
      setState((prev) => {
        const newPrevMsg = prev.messages.slice(0, -1);
        return {
          ...prev,
          messages: [...newPrevMsg, botMessage],
          isInputDisabled: false,
        };
      });
      refreshPrompt();
    } else if (responseMessage?.value && applicationType !== "FAQ") {
      let getIdToken;
      if (authState?.isAuthenticated) {
        getIdToken = oktaAuth.getIdToken();
      }
      const requestBody = { sqlquery: responseMessage?.value };
      postApiCall(apiUrl, getIdToken, requestBody)
        .then((response) => {
          const result = response?.data;
          let botMessage;
          if (result?.length) {
            botMessage = createChatBotMessage(<TableComponent data={result} />);
          } else {
            botMessage = createChatBotMessage(
              "Sorry could not find any information related to your question"
            );
          }
          if (botMessage) {
            setState((prev) => {
              const newPrevMsg = prev.messages.slice(0, -1);
              return {
                ...prev,
                messages: [...newPrevMsg, botMessage],
                isInputDisabled: false,
              };
            });
            refreshPrompt();
          }
        })
        .catch((err) => {
          console.log("error from bot api", err);
        });
    } else {
      const botMessage = createChatBotMessage(
        responseMessage ||
          "Sorry could not find any information related to your question"
      );
      setState((prev) => {
        const newPrevMsg = prev.messages.slice(0, -1);
        return {
          ...prev,
          messages: [...newPrevMsg, botMessage],
          isInputDisabled: false,
        };
      });
      refreshPrompt();
    }
  };
  useEffect(() => {
    const elements = document.querySelectorAll(
      ".react-chatbot-kit-chat-btn-send"
    );
    if (state.isInputDisabled) {
      dispatch({
        type: IS_LOADING,
        payload: true,
      });
      elements[0].classList.add("disable-btn");
    } else {
      dispatch({
        type: IS_LOADING,
        payload: false,
      });
      elements[0].classList.remove("disable-btn");
    }
  }, [state.isInputDisabled]);

  useEffect(() => {
    setConversationId(null);
    setParentMessageId(null);
  }, [applicationType]);
  // Put the function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleConfirmity,
            handleHubDocuments,
            handleAccountInfo,
            handleAccountAlerts,
            handleFaq,
            handleFinance,
            handleFinanceInfo,
            handleFinancePrompt,
            handleFaqMessage,
            handleValidation,
          },
        });
      })}
      {sessionAlert && <WarningMessage show={true} />}
    </div>
  );
};

export default ActionProvider;
