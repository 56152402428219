export const applicationID = {
  faq: "e91494dd-835b-4248-b09b-44f3814c554d",
  // faq: "41bf181d-8425-4541-9ec1-b0ac4a8297cb",
  finance: "d9529ffa-f269-4a10-b2d9-b0f76fb9209a",
  conformity: "3f30a399-2af3-4c09-882d-9e5109c76f2b",
};

export const pluginData = {
  faq: "305bbd08-d17e-4cfe-9ac8-654a8f833659",
  finance: "305bbd08-d17e-4cfe-9ac8-654a8f833659",
  conformity: "1917a04d-c2e8-42c2-95e6-f0ad6733d58e",
};

export const roleDetails = {
  roleArn: "arn:aws:iam::058264283013:role/QApplicationRole",
  roleSessionName: "identity-bearer-for-Q-user",
};
