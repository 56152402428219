import React, { useEffect, useState, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

// Roles
import {
  CS_Hub_AIUsecases_Infosec,
  CS_Hub_AWS_Account_Request_Admin,
  Everyone_Role,
} from "roles/roles";

import SpinnerLoader from "components/Common/SpinnerLoader";
import DangerAlert from "components/Message/DangerAlert";
import CustomModal from "components/Modal/CustomModal";

import ViewBox from "./ViewBox";
import EditBox from "./EditBox";
import Approve from "./Approve";
import Decline from "./Decline";
import Cancel from "./Cancel";

// Helper
import { postApiCall } from "helper/postApiCall";

// Import Icon
import dots from "icons/3-dots.svg";

import { t } from "i18next";
import { AI_REQUEST_RELOAD } from "redux/ai-request/aiRequestConstant";

const ViewTable = () => {
  const dispatch = useDispatch();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const aiRequestReload = useSelector((state) => state.aiRequestReload);
  const { aiRequestReloadType } = aiRequestReload;

  // API State
  const [apiLoading, setApiLoading] = useState(true);
  const [apiResponseData, setApiResponseData] = useState([]);
  const [apiError, setApiError] = useState(null);

  // API Variables
  let apiUrl = `${process.env.REACT_APP_AI_REQUEST_VIEW}`;
  const idToken = `${userInfo.idToken}`;
  let requestBody = "";
  if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
    requestBody = `{"type_of_user":1}`;
  } else {
    requestBody = `{"submittedBy":"${userInfo.preferred_username}", "type_of_user":0}`;
  }

  const fetchTableData = useCallback(() => {
    setApiLoading(true);
    setApiResponseData([]);

    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        console.log("view request response", response);
        setApiResponseData(response.data?.items);
        setApiLoading(false);
      })
      .catch((err) => {
        setApiError(
          "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
        );
        setApiLoading(false);
      });
  }, [apiUrl, idToken, requestBody]);

  // ! Option Part
  const [options, setOptions] = useState({
    showOption: null,
    selectedOptionUID: null,
    selectedOptionData: {},
  });

  // Option Click Handler
  function optionHandler(selectedOptionDataValues, isMenuOpen = null) {
    if (options.showOption || isMenuOpen) {
      setOptions({
        showOption: false,
        selectedOptionUID: selectedOptionDataValues.uid,
        selectedOptionData: selectedOptionDataValues,
      });
    } else {
      setOptions({
        showOption: true,
        selectedOptionUID: selectedOptionDataValues.uid,
        selectedOptionData: selectedOptionDataValues,
      });
    }
  }

  // ! View Part
  // ! View Box State & Callback
  const [showViewBox, setShowViewBox] = useState(false);

  // ! Close View Callback
  const closeViewCallback = useCallback((value) => {
    setShowViewBox(value);
  }, []);

  // ! Edit Part
  // ! Edit Box State & Callback
  const [showEditBox, setShowEditBox] = useState(false);

  // ! Open Edit Callback
  const openEditCallback = useCallback((value) => {
    setShowEditBox(value);
  }, []);

  // ! Close Edit Callback
  const closeEditCallback = useCallback((value) => {
    setShowEditBox(value);
  }, []);

  // ! Modal Part
  // ! Modal State & Callback
  const [showModalBox, setShowModalBox] = useState(null);

  // ! Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowModalBox(value);
  }, []);

  // !  Modal Yes Click Part & Reload Table Data
  const yesCallback = useCallback(
    (value) => {
      setShowModalBox(value);
      fetchTableData();
    },
    [fetchTableData]
  );

  const columns = [
    {
      dataField: "dummyUid",
      isDummyField: true,
      text: t("request_id"),
      sort: true,
      formatter: (cell, row) => {
        return <span className="text-uppercase">{`${row.uid}`}</span>;
      },
    },
    {
      dataField: "applicationName",

      text: t("Application Name"),
      sort: true,
      searchable: true,
    },

    {
      dataField: "gcsapproval",
      text: t("GCS Approval"),
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (
          row.gcsapproval === "Approved" ||
          row.gcsapproval === "Processing"
        ) {
          return (
            <div className="position-relative text-center">
              <div
                className="approved-badge text-capitalize d-inline"
                style={{ fontSize: "11px" }}
              >
                {row.gcsapproval}
              </div>
              <i class="fa fa-long-arrow-right d-inline-block batch-left-arrow"></i>
            </div>
          );
        }
        if (row.gcsapproval === "Pending") {
          return (
            <div className="position-relative text-center">
              <div
                className="pending-badge text-capitalize d-inline"
                style={{ fontSize: "11px" }}
              >
                {row.gcsapproval}
              </div>
              <i class="fa fa-long-arrow-right d-inline-block batch-left-arrow"></i>
            </div>
          );
        }
        if (row.gcsapproval === "Cancelled") {
          return (
            <div
              className="canceled-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.gcsapproval}
            </div>
          );
        }
        if (row.gcsapproval === "Err") {
          return (
            <div
              className="canceled-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              Error
            </div>
          );
        }
        if (row.gcsapproval === "Declined") {
          return (
            <div
              className="declined-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.gcsapproval}
            </div>
          );
        }
      },
    },

    {
      dataField: "infosecapproval",
      text: t("Infosec Approval"),
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (
          row.infosecapproval === "Approved" ||
          row.infosecapproval === "Processing"
        ) {
          return (
            <div className="position-relative text-center">
              <div
                className="approved-badge text-capitalize d-inline"
                style={{ fontSize: "11px" }}
              >
                {row.infosecapproval}
              </div>
              {/* <span class="d-inline-block batch-left-arrow infosec-risk-or">
                ||
              </span> */}
            </div>
          );
        }
        if (row.infosecapproval === "Pending") {
          return (
            <div className="position-relative text-center">
              <div
                className="pending-badge text-capitalize d-inline"
                style={{ fontSize: "11px" }}
              >
                {row.infosecapproval}
              </div>
              {/* <span class="d-inline-block batch-left-arrow infosec-risk-or">
                ||
              </span> */}
            </div>
          );
        }
        if (row.infosecapproval === "Cancelled") {
          return (
            <div
              className="canceled-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.infosecapproval}
            </div>
          );
        }
        if (row.infosecapproval === "Err") {
          return (
            <div
              className="canceled-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              Error
            </div>
          );
        }
        if (row.infosecapproval === "Declined") {
          return (
            <div
              className="declined-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.infosecapproval}
            </div>
          );
        }
      },
    },
    {
      dataField: "riskapproval",
      text: t("Risk Approval"),
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (
          row.riskapproval === "Approved" ||
          row.riskapproval === "Processing"
        ) {
          return (
            <div className="position-relative text-center">
              <div
                className="approved-badge text-capitalize d-inline"
                style={{ fontSize: "11px" }}
              >
                {row.riskapproval}
              </div>
              <i class="fa fa-long-arrow-right d-inline-block batch-left-arrow"></i>
            </div>
          );
        }
        if (row.riskapproval === "Pending") {
          return (
            <div className="position-relative text-center">
              <div
                className="pending-badge text-capitalize d-inline"
                style={{ fontSize: "11px" }}
              >
                {row.riskapproval}
              </div>
              <i class="fa fa-long-arrow-right d-inline-block batch-left-arrow"></i>
            </div>
          );
        }
        if (row.riskapproval === "Cancelled") {
          return (
            <div
              className="canceled-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.riskapproval}
            </div>
          );
        }
        if (row.riskapproval === "Err") {
          return (
            <div
              className="canceled-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              Error
            </div>
          );
        }
        if (row.riskapproval === "Declined") {
          return (
            <div
              className="declined-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.riskapproval}
            </div>
          );
        }
      },
    },
    // {
    //   dataField: 'uid',
    //   text: 'CREATED ON',
    //   sort: true,
    //   formatter: (cellContent, row) => {
    //     let dateConverted = moment(parseInt(row.uid.split('-')[1])).format(
    //       'MMM DD, YYYY hh:mm A'
    //     );
    //     return <span>{dateConverted}</span>;
    //   },
    //   sortFunc: (a, b, order) => {
    //     let dateConvertedA = moment(parseInt(a.split('-')[1])).format(
    //       'MMM DD, YYYY hh:mm A'
    //     );
    //     let dateConvertedB = moment(parseInt(b.split('-')[1])).format(
    //       'MMM DD, YYYY hh:mm A'
    //     );
    //     if (order === 'asc') {
    //       return Date.parse(dateConvertedA) - Date.parse(dateConvertedB);
    //     } else if (order === 'desc') {
    //       return Date.parse(dateConvertedB) - Date.parse(dateConvertedA);
    //     }
    //   },
    // },
    {
      dataField: "overallapproval",
      text: t("Overall Approval"),
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (
          row.overallapproval === "Approved" ||
          row.overallapproval === "Processing"
        ) {
          return (
            <div className=" text-center">
              <div
                className="approved-badge text-capitalize d-inline"
                style={{ fontSize: "11px" }}
              >
                {row.overallapproval}
              </div>
            </div>
          );
        }
        if (row.overallapproval === "Pending") {
          return (
            <div className=" text-center">
              <div
                className="pending-badge text-capitalize d-inline"
                style={{ fontSize: "11px" }}
              >
                {row.overallapproval}
              </div>
            </div>
          );
        }
        if (row.overallapproval === "Cancelled") {
          return (
            <div
              className="canceled-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.overallapproval}
            </div>
          );
        }
        if (row.overallapproval === "Err") {
          return (
            <div
              className="canceled-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              Error
            </div>
          );
        }
        if (row.overallapproval === "Declined") {
          return (
            <div
              className="declined-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.overallapproval}
            </div>
          );
        }
      },
    },
    {
      dataField: "dummyActionField",
      text: "",
      formatExtraData: options,
      formatter: (cellContent, row) => {
        return (
          <div
            className="custom-table-option-conatiner"
            tabIndex="0"
            onBlur={() => {
              optionHandler(row, "open");
            }}
            onClick={() => {
              optionHandler(row);
            }}
          >
            <img src={dots} alt="action" className="p-2" />

            {options.showOption === true &&
              options.selectedOptionUID === row.uid && (
                <div className="custom-table-option">
                  <ul>
                    {userInfo.groups.includes(
                      CS_Hub_AWS_Account_Request_Admin
                    ) && row.overallapproval === "Pending" ? (
                      <>
                        <li
                          onClick={() => {
                            setShowViewBox(true);
                          }}
                        >
                          View
                        </li>
                        <li
                          onClick={() => {
                            setShowEditBox(true);
                          }}
                          className="disable-div"
                        >
                          Edit
                        </li>
                        <li
                          onClick={() => {
                            setShowModalBox("Approve");
                          }}
                        >
                          Approve
                        </li>
                        <li
                          onClick={() => {
                            setShowModalBox("Cancel");
                          }}
                        >
                          Cancel
                        </li>
                        <li
                          onClick={() => {
                            setShowModalBox("Decline");
                          }}
                        >
                          Decline
                        </li>
                      </>
                    ) : userInfo.groups.includes(CS_Hub_AIUsecases_Infosec) &&
                      row.overallapproval === "Pending" ? (
                      <>
                        <li
                          onClick={() => {
                            setShowViewBox(true);
                          }}
                        >
                          View
                        </li>

                        <li
                          onClick={() => {
                            setShowModalBox("Approve");
                          }}
                        >
                          Approve
                        </li>

                        <li
                          onClick={() => {
                            setShowModalBox("Decline");
                          }}
                        >
                          Decline
                        </li>
                      </>
                    ) : userInfo.groups.includes(Everyone_Role) &&
                      row.overallapproval === "Pending" ? (
                      <>
                        <li
                          onClick={() => {
                            setShowViewBox(true);
                          }}
                        >
                          View
                        </li>
                        <li
                          onClick={() => {
                            setShowEditBox(true);
                          }}
                          className="disable-div"
                        >
                          Edit
                        </li>
                        <li
                          onClick={() => {
                            setShowModalBox("Cancel");
                          }}
                        >
                          Cancel
                        </li>
                      </>
                    ) : row.overallapproval === "Approved" ||
                      row.overallapproval === "Processing" ||
                      row.overallapproval === "Declined" ||
                      row.overallapproval === "Cancelled" ? (
                      <li
                        onClick={() => {
                          setShowViewBox(true);
                        }}
                      >
                        View
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              )}
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "uid",
      order: "asc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {t("showing_results", { from, to, size })}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: apiResponseData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    if (aiRequestReloadType === true) {
      fetchTableData();
      dispatch({
        type: AI_REQUEST_RELOAD,
        payload: false,
      });
    }

    return () => {};
  }, [aiRequestReloadType]);

  useEffect(() => {
    fetchTableData();
    return () => {
      setApiResponseData([]);
    };
  }, []);

  return (
    <>
      {apiLoading && (
        <div className="mt-3">
          <SpinnerLoader />
        </div>
      )}

      {apiLoading === false && apiError !== null && (
        <Row>
          <Col md={12} className="d-flex justify-content-center mt-4">
            <DangerAlert message={apiError} />
          </Col>
        </Row>
      )}

      {apiLoading === false && (
        <Row>
          <Col>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="uid"
              columns={columns}
              data={apiResponseData}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="uid"
                  columns={columns}
                  data={apiResponseData}
                  search
                >
                  {(toolkitProps) => (
                    <>
                      <Row className="d-flex align-items-center">
                        <Col md={6} className="mb-2">
                          <span className="subtitle-text">
                            {t("AI Requests")}
                          </span>
                        </Col>

                        <Col md={3} className="mb-2">
                          <div className="search-input-group">
                            <i className="fa fa-search"></i>
                            <SearchBar
                              {...toolkitProps.searchProps}
                              placeholder={t("search_placeholder")}
                              className="custom-search-input-form-control"
                            />
                          </div>
                        </Col>

                        <Col md={3} className="mb-2">
                          <Link to="/ai-request-new">
                            <button className="teal-filled-btn w-100">
                              <i className="fa fa-plus mx-2"></i>
                              {t("new_request")}
                            </button>
                          </Link>
                        </Col>
                      </Row>

                      <>
                        <Row>
                          <Col md={12} xl={12} className="mt-4">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"uid"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={
                                  "custom-table-head custom-pointer"
                                }
                                bodyClasses={"custom-table-body"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                          <Col>
                            {apiResponseData.length === 0 && (
                              <div className="custom-error-message text-center">
                                No Data to Display
                              </div>
                            )}
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-3">
                          <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                            <div>
                              <PaginationTotalStandalone {...paginationProps} />
                            </div>
                            <div>
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </>
                    </>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </Col>
        </Row>
      )}

      {showViewBox && (
        <ViewBox
          data={options.selectedOptionData}
          closeViewParent={closeViewCallback}
          openEditParent={openEditCallback}
        />
      )}

      {showEditBox && (
        <EditBox
          data={options.selectedOptionData}
          closeEditParent={closeEditCallback}
        />
      )}

      {showModalBox === "Approve" && (
        <CustomModal
          showModal={true}
          children={
            <Approve
              closeModalParent={closeModalCallback}
              yesParent={yesCallback}
              status={options.selectedOptionData.overallapproval}
              uid={options.selectedOptionUID}
            />
          }
        />
      )}

      {showModalBox === "Decline" && (
        <CustomModal
          showModal={true}
          children={
            <Decline
              closeModalParent={closeModalCallback}
              yesParent={yesCallback}
              status={options.selectedOptionData.overallapproval}
              uid={options.selectedOptionUID}
            />
          }
        />
      )}

      {showModalBox === "Cancel" && (
        <CustomModal
          showModal={true}
          children={
            <Cancel
              closeModalParent={closeModalCallback}
              yesParent={yesCallback}
              status={options.selectedOptionData.overallapproval}
              uid={options.selectedOptionUID}
            />
          }
        />
      )}
    </>
  );
};

export default ViewTable;
