import { AI_REQUEST_RELOAD } from "./aiRequestConstant";

export const aiRequestReloadReducer = (
  state = {
    aiRequestReloadType: false,
  },
  action
) => {
  switch (action.type) {
    case AI_REQUEST_RELOAD:
      return { ...state, aiRequestReloadType: action.payload };

    default:
      return state;
  }
};
